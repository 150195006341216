import React, { createContext, useState, useMemo, useContext } from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

const ThemeContext = createContext();

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: '#ffffff',
            paper: '#f5f5f5',
        },
        text: {
            primary: '#000000',
        },
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#000',
            paper: '#1a1a1a', //this is especially for MUI components
        },
        text: {
            primary: '#ffffff',
        },
    },
    isDarkMode: true
});

export const ThemeContextProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState('Dark');

    const theme = useMemo(() => (isDarkMode == 'Dark' ? darkTheme : lightTheme), [isDarkMode]);

    const toggleTheme = () => setIsDarkMode((prevMode) => !prevMode);

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            <ThemeProvider theme={theme}>
                <CssBaseline /> 
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => useContext(ThemeContext);
