import React, { useEffect } from "react";
import ContactForm from '../../contact-form/contactForm';
import Footer from "../../footer/footer";
import "./newsDetails.scss";
import { ThemeContextProvider } from "../../../themecontext";

const NewsDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="news-details">
      <ThemeContextProvider>
        <ContactForm />
      </ThemeContextProvider>
      <Footer />
    </div>
  );
};

export default NewsDetails;
