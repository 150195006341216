import React, { useEffect } from "react";
import Footer from "../footer/footer";
import "./privacyPolicy.scss";
import { Helmet } from "react-helmet-async";

const PrivacyPlicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Helmet>
        <title>GFL MMA League | 2025 Fighter Comebacks & MMA's New Era</title>
        <meta property="og:title" content="GFL MMA League | 2025 Fighter Comebacks & MMA's New Era" />
        <meta name="description" content="Discover the GFL MMA League, launching in 2025 with top fighters returning to fight. Stay tuned for exclusive fighter updates, event announcements, and streaming details." />
        <meta name="keywords" content="MMA league 2025,GFL MMA league,MMA comeback 2025,mixed martial arts 2025,GFL fight schedule,MMA streaming platform,MMA promotions 2025,MMA fights 2025,top MMA fighters 2025" />
      </Helmet>
      <div className="privacy-policy">
        <div className="container">
          <h1 className="text-center pb-5">Privacy Policy</h1>
          <h2>Global Fight League (GFL) Privacy Policy</h2>
          <p>
            <strong>Effective Date:</strong> 1 November 2024
          </p>
          <h2>1. Introduction</h2>
          <p>
            Global Fight League ("GFL," "we," "us," or "our") respects your
            privacy and is committed to protecting your personal data. This
            Privacy Policy explains our practices regarding information
            collection, usage, and sharing related to the GFL and GFL APP
            platforms, including our interactive features, marketplaces, and
            blockchain-based utilities.
          </p>

          <h2>2. Information We Collect</h2>
          <h3>a. Personal Information</h3>
          <p>
            We collect and store personal data you provide, including but not
            limited to:
          </p>
          <ul>
            <li>
              <strong>Account Registration:</strong> Name, email, phone number,
              age verification (for age-restricted content), username, and
              password.
            </li>
            <li>
              <strong>Profile and Preferences:</strong> Profile photo, selected
              preferences, location data, interests in specific leagues or
              promotions.
            </li>
            <li>
              <strong>Financial Information:</strong> Payment card details,
              billing address, and transaction history to facilitate purchases
              (managed securely via third-party processors).
            </li>
            <li>
              <strong>User Communications:</strong> Chat logs, comments,
              feedback, and any correspondence with customer support.
            </li>
          </ul>

          <h3>b. Non-Personal Information</h3>
          <p>
            Collected automatically for analytics, user experience, and
            optimization:
          </p>
          <ul>
            <li>
              <strong>Technical Data:</strong> IP address, browser type,
              operating system, device identifiers.
            </li>
            <li>
              <strong>Usage Data:</strong> Engagement metrics, page views,
              interaction with features (such as live chat, in-stream polls),
              event attendance, and purchasing behavior.
            </li>
          </ul>

          <h3>c. Blockchain and Web3 Information</h3>
          <p>
            For crypto and token-based transactions, we may collect wallet
            addresses, transaction data, and blockchain-related identifiers for
            utility on the GFL platform and GFL APP.
          </p>

          <h2>3. How We Use Your Information</h2>
          <p>Your data helps us enhance and tailor our services:</p>
          <ul>
            <li>
              <strong>Account Management:</strong> To facilitate login,
              personalize your experience, and manage user profiles.
            </li>
            <li>
              <strong>Platform Features:</strong> To provide you access to live
              streams, interactive features, fan forums, and special events.
            </li>
            <li>
              <strong>Payment Processing:</strong> To complete transactions for
              ticketing, merchandise, subscriptions, NFT purchases, and event
              access.
            </li>
            <li>
              <strong>KOPL Token and Web3 Utility:</strong> To enable
              transactions within GFL APP for promotions, collectibles, and
              token-gated experiences.
            </li>
            <li>
              <strong>Content Personalization:</strong> To recommend events,
              updates, or content based on your engagement history and
              preferences.
            </li>
            <li>
              <strong>Marketing and Communication:</strong> To send updates,
              promotions, personalized offers, and notifications.
            </li>
            <li>
              <strong>Legal and Compliance:</strong> To comply with applicable
              laws and manage legal claims or security concerns.
            </li>
          </ul>

          <h2>4. Information Sharing and Disclosure</h2>
          <p>We may share your information in specific circumstances:</p>
          <ul>
            <li>
              <strong>Service Providers:</strong> Trusted third-party vendors
              assist in providing platform services (e.g., payment processing,
              blockchain management, analytics, and customer support).
            </li>
            <li>
              <strong>Platform Partnerships:</strong> We may share anonymized
              and aggregated data with GFL APP partners to enhance services
              and offer better engagement for fans and athletes.
            </li>
            <li>
              <strong>Public Content:</strong> Comments, reviews, or content you
              share publicly on forums or social media associated with GFL APP
              may be visible to others.
            </li>
            <li>
              <strong>Legal Obligations:</strong> We may disclose information in
              response to legal requests, such as subpoenas or lawful
              investigations.
            </li>
          </ul>

          <h2>5. Data Security</h2>
          <p>
            We utilize industry-standard measures, including encryption, secure
            data storage, and limited-access policies, to safeguard personal
            information. However, no system is foolproof, and users should also
            take precautions to secure their account details.
          </p>

          <h2>6. Your Rights</h2>
          <p>You may exercise specific rights regarding your data:</p>
          <ul>
            <li>
              <strong>Access and Correction:</strong> Request to view or correct
              your personal information.
            </li>
            <li>
              <strong>Deletion:</strong> Request data deletion, subject to legal
              or operational requirements.
            </li>
            <li>
              <strong>Blockchain Transparency:</strong> Blockchain-based data
              (e.g., token transactions) is immutable; therefore, users should
              be mindful that this information cannot be altered or deleted
              post-transaction.
            </li>
            <li>
              <strong>Marketing Opt-Out:</strong> Adjust email preferences or
              opt-out of promotional messages.
            </li>
          </ul>

          <h2>7. Cookie Policy and Tracking Technologies</h2>
          <p>
            GFL and GFL APP use cookies and similar tracking technologies to
            optimize user experience. See the Cookie Policy below for details on
            managing your cookie preferences.
          </p>

          <h2>8. Retention of Data</h2>
          <p>
            We retain your personal data as long as necessary to fulfill the
            purposes outlined in this Privacy Policy, or as required by law. For
            users who engage in token transactions, blockchain transaction
            records are retained permanently within the blockchain network.
          </p>

          <h2>9. Contact Information</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at <a href="mailto:privacy@gfl.com">privacy@gfl.com</a> or reach
            out through our customer support channels on the GFL and GFL APP
            platforms.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPlicy;
