import React, { useEffect, useState } from "react";
import Footer from "../footer/footer";
import { ThemeContextProvider } from "../../themecontext";
import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import "./media.scss";

const Media = () => {
  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Helmet>
        <title>About GFL MMA League | Revolutionizing Mixed Martial Arts</title>
        <meta
          name="description"
          content="Learn about the GFL MMA League, our mission to transform mixed martial arts, and how we’re creating new opportunities for fighters and fans in 2025."
        />
        <meta
          name="keywords"
          content="about GFL MMA league,GFL MMA 2025,MMA fighter-focused league,MMA sports organization,MMA combat sports 2025,GFL mission MMA,GFL fighter comeback stories,GFL MMA vision,MMA revenue sharing model,team-based MMA league"
        />
      </Helmet>
      <div className="media">
        <div className="container">
          <h1 className="text-center pb-4">Media</h1>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  //   variant="scrollable"
                  centered
                  // scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Press Release" value="1" />
                  <Tab label="Videos" value="2" />
                  <Tab label="News" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="pt-4">
                  <div className="pr-card">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pr-img">
                          <img
                            src={require("../../assets/PR-MMA-April-2025.png")}
                            className="mw-100"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 py-4">
                        <h3>
                          Global Fight League Announces Initial Funding and Sets
                          Launch Plan for New Team-Based MMA Property
                        </h3>
                        <div className="pb-2 date">Dec 11, 2024 at 4:00 PM EST</div>
                        <p>
                          The Global Fight League, a new team-based MMA
                          property, today announced that it has successfully
                          closed a multi-million-dollar seed round and is set to
                          launch its first season in April 2025.{" "}
                        </p>
                        <a
                          href="https://www.prweb.com/releases/global-fight-league-announces-initial-funding-and-sets-launch-plan-for-new-team-based-mma-property-302328655.html"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <iframe
                      width="100%"
                      height="500"
                      src="https://www.youtube.com/embed/COvnTczdaxk?si=_s-hO6fobAZS2DFn"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div className="pt-4">
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/newsweek-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Multiple Former UFC Stars Added to New Global Fight
                          League Organization
                        </h3>
                        <div className="pb-2 date">
                          Dec 11, 2024 at 3:01 PM EST
                        </div>
                        <p>
                          The MMA space is set to grow even more, as a new
                          organization has been announced. That new organization
                          is called the "Global Fight League," and it is headed
                          by Darren Owen.
                        </p>
                        <a
                          href="https://www.newsweek.com/sports/multiple-former-ufc-stars-added-new-global-fight-league-organization-1999424"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/forbes-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                        10 Former UFC Champions Headline New MMA Organization’s Roster
                        </h3>
                        <div className="pb-2 date">Dec 11, 2024 at 06:45 PM EST</div>
                        <p>
                        The new team-based MMA organization was unveiled on The Ariel Helwani Show on Wednesday, December 11, and it was boosted by an interesting financial model that promises a 50-50 revenue split with fighters, and a roster packed with names MMA fans will immediately recognize.
                        </p>
                        <a
                          href="https://www.forbes.com/sites/brianmazique/2024/12/11/10-former-ufc-champions-headline-new-mma-organizations-roster/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/yahoo-sports-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Global Fight League announces launch, roster includes
                          giant list of former UFC fighters
                        </h3>
                        <div className="pb-2 date">Dec 11, 2024 at 5:45 PM EST</div>
                        <p>
                          A new player has entered the MMA landscape, and it has
                          arrived with some big names.
                        </p>
                        <a
                          href="https://sports.yahoo.com/global-fight-league-announces-launch-224513993.html"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/mmaj-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                        Global Fight League announces launch, roster includes giant list of former UFC fighters
                        </h3>
                        <div className="pb-2 date">Dec 11, 2024 at 5:45 PM EST</div>
                        <p>
                        A new player has entered the MMA landscape, and it has arrived with some big names. Wednesday, Global Fight League (GFL) announced its official launch will come in the opening weeks of 2025.
                        </p>
                        <a
                          href="https://mmajunkie.usatoday.com/2024/12/global-fight-league-launch-ufc-woodley-silva-werdum"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/yahoo-sports-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Anthony Pettis, Kevin Lee explain details of new GFL
                          deals: 'I couldn't say no to this kind of contract'
                        </h3>
                        <div className="pb-2 date">Dec 11, 2024 at 6:45 PM EST</div>
                        <p>
                          MMA's newest organization Global Fight League (GFL) is
                          officially set to debut in 2025.
                        </p>
                        <a
                          href="https://sports.yahoo.com/anthony-pettis-kevin-lee-explain-details-of-new-gfl-deals-i-couldnt-say-no-to-this-kind-of-contract-235530319.html"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/sherdog-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                        Global Fight League Reveals Team-Based Format, Roster with Multiple Ex-UFC Champs
                        </h3>
                        <div className="pb-2 date">Dec 11, 2024 </div>
                        <p>
                        On Wednesday, promotion founder Darren Owen announced plans to launch a team-based MMA league with a season format beginning in April 2025. According to a release, the league will feature six teams in “major global markets.”
                        </p>
                        <a
                          href="https://www.sherdog.com/news/news/Global-Fight-League-Reveals-TeamBased-Format-Roster-with-Multiple-ExUFC-Champs-195692"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/yahoo-sports-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Global Fight League reveals massive list of signings,
                          including 8 ex-UFC champs, for new team-based MMA
                          organization
                        </h3>
                        <div className="pb-2 date">Dec 12, 2024 at 4:08 PM EST</div>
                        <p>
                          The Global Fight League (GFL) is ready to take the MMA
                          world by storm. GFL founder Darren Owen revealed
                          Wednesday on Uncrowned's "The Ariel Helwani Show" that
                          the promotion's first events will occur in April 2025.
                        </p>
                        <a
                          href="https://uk.sports.yahoo.com/news/global-fight-league-reveals-massive-list-of-signings-including-8-ex-ufc-champs-for-new-team-based-mma-organization-201248109.html"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  
                  
                  
                  
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Media;
