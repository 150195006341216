import React, { useEffect } from "react";
import Footer from "../footer/footer";
import "./aboutUs.scss";
import { Helmet } from "react-helmet-async";

import Darren from "../../assets/darren.png";
import Arun from "../../assets/arun.png";
import Scott from "../../assets/scott.png";
import Jeffrey from "../../assets/jeffrey.png";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Helmet>
        <title>About GFL MMA League | Revolutionizing Mixed Martial Arts</title>
        <meta
          name="description"
          content="Learn about the GFL MMA League, our mission to transform mixed martial arts, and how we’re creating new opportunities for fighters and fans in 2025."
        />
        <meta
          name="keywords"
          content="about GFL MMA league,GFL MMA 2025,MMA fighter-focused league,MMA sports organization,MMA combat sports 2025,GFL mission MMA,GFL fighter comeback stories,GFL MMA vision,MMA revenue sharing model,team-based MMA league"
        />
      </Helmet>
      <div className="about-us">
        <div className="bg-img">
          <div className="container">
            <h1 className="text-center">Mission</h1>
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <p>
                The Global Fight League is a necessary evolution for the future generation of mixed martial artists. Our mission is to build a world-class, athlete-first league rooted in integrity, collaboration, and a shared passion for mixed martial arts— sharing revenues with our athletes, and delivering unparalleled excitement to fans.</p>
                <p>
                For the first time in the history of combat sports, the GFL is implementing a true team-based league model for MMA. GFL athletes become true equitable partners with 50% of all event revenue sources going directly to them. In addition, the GFL is contributing 8% of each athlete's purse into a retirement fund and a 2% contribution going towards insurance.
                                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1 className="text-center pb-5">LEADERSHIP TEAM</h1>
          <div className="container">
              <div className="d-md-flex align-items-center pb-5 text-center text-md-start">
                  <img src={Darren} className="me-md-5 mb-3 mb-md-0"></img>
                  <div>
                      <h2 className="primary-color">Darren Owen</h2>
                      <div className="team-designation pb-2">Co-Founder and Acting Commissioner</div>
                      <p>Darren has 16 years of experience founding and growing multiple MMA organizations globally,  promoting, matchmaking and producing live events for NBC, CBS, IMG and Fox Sports Asia. </p>
                  </div>
              </div>
          </div>
          <div className="container">
              <div className="d-grid d-md-flex align-items-center pb-5 text-center text-md-start">
                  <div className="text-md-end order-2 order-md-1">
                      <h2 className="primary-color">Arun Parimi</h2>
                      <div className="team-designation pb-2">Co-Founder / COO </div>
                      <p>Arun has 26 years experience in business transformation, event management, solution architecture, engineering and SaaS with global delivery for large enterprises and built innovative products. </p>
                  </div>
                  <div className="ms-md-5 order-1 order-md-2  mb-3 mb-md-0"><img src={Arun}></img></div>
              </div>
          </div>
          <div className="container">
              <div className="d-md-flex align-items-center pb-5 text-center text-md-start">
                  <img src={Scott} className="me-md-5 mb-3 mb-md-0"></img>
                  <div>
                      <h2 className="primary-color">Scott Parker</h2>
                      <div className="team-designation pb-2">CMO</div>
                      <p>Scott is a marketing leader with 25 years experience launching/scaling businesses and building iconic brands at PepsiCo and Disney/ESPN leading the marketing efforts for the NFL, college football, Global X Games, and most recently the launch of the XFL. </p>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
