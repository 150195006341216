import React, { useEffect, useState } from "react";
import ContactForm from "../contact-form/contactForm";
import Button from "react-bootstrap/Button";
import Footer from "../footer/footer";
import { ThemeContextProvider } from "../../themecontext";
import { Helmet } from "react-helmet-async";
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import "./fighters.scss";

import MHWPic1 from "../../assets/fighter/men-heavyweight/pic1.png";
import MHWPic2 from "../../assets/fighter/men-heavyweight/pic2.png";
import MHWPic3 from "../../assets/fighter/men-heavyweight/pic3.png";
import MHWPic4 from "../../assets/fighter/men-heavyweight/pic4.png";
import MHWPic5 from "../../assets/fighter/men-heavyweight/pic5.png";
import MHWPic6 from "../../assets/fighter/men-heavyweight/pic6.png";
import MHWPic7 from "../../assets/fighter/men-heavyweight/pic7.png";
import MHWPic8 from "../../assets/fighter/men-heavyweight/pic8.png";
import MHWPic9 from "../../assets/fighter/men-heavyweight/pic9.png";
import MHWPic10 from "../../assets/fighter/men-heavyweight/pic10.png";
import MHWPic11 from "../../assets/fighter/men-heavyweight/pic11.png";
import MHWPic12 from "../../assets/fighter/men-heavyweight/pic12.png";
import MHWPic13 from "../../assets/fighter/men-heavyweight/pic13.png";
import MHWPic14 from "../../assets/fighter/men-heavyweight/pic14.png";


import MLHWPic1 from "../../assets/fighter/men-light-heavyweight/pic1.jpg";
import MLHWPic2 from "../../assets/fighter/men-light-heavyweight/pic2.jpg";
import MLHWPic3 from "../../assets/fighter/men-light-heavyweight/pic3.jpg";
import MLHWPic4 from "../../assets/fighter/men-light-heavyweight/pic4.jpg";
import MLHWPic5 from "../../assets/fighter/men-light-heavyweight/pic5.jpg";


import MMWPic1 from "../../assets/fighter/men-middleweight/pic1.jpg";
import MMWPic2 from "../../assets/fighter/men-middleweight/pic2.jpg";
import MMWPic3 from "../../assets/fighter/men-middleweight/pic3.jpg";
import MMWPic4 from "../../assets/fighter/men-middleweight/pic4.jpg";
import MMWPic5 from "../../assets/fighter/men-middleweight/pic5.jpg";
import MMWPic6 from "../../assets/fighter/men-middleweight/pic6.jpg";
import MMWPic7 from "../../assets/fighter/men-middleweight/pic7.jpg";
import MMWPic8 from "../../assets/fighter/men-middleweight/pic8.jpg";
import MMWPic9 from "../../assets/fighter/men-middleweight/pic9.jpg";
import MMWPic10 from "../../assets/fighter/men-middleweight/pic10.jpg";
import MMWPic11 from "../../assets/fighter/men-middleweight/pic11.jpg";


import MWWPic1 from "../../assets/fighter/men-welterweight/pic1.jpg";
import MWWPic2 from "../../assets/fighter/men-welterweight/pic2.jpg";
import MWWPic3 from "../../assets/fighter/men-welterweight/pic3.jpg";
import MWWPic4 from "../../assets/fighter/men-welterweight/pic4.jpg";
import MWWPic5 from "../../assets/fighter/men-welterweight/pic5.jpg";
import MWWPic6 from "../../assets/fighter/men-welterweight/pic6.jpg";
import MWWPic7 from "../../assets/fighter/men-welterweight/pic7.jpg";
import MWWPic8 from "../../assets/fighter/men-welterweight/pic8.jpg";
import MWWPic9 from "../../assets/fighter/men-welterweight/pic9.jpg";
import MWWPic10 from "../../assets/fighter/men-welterweight/pic10.jpg";
import MWWPic11 from "../../assets/fighter/men-welterweight/pic11.jpg";

import MLWPic1 from "../../assets/fighter/men-lightweight/pic1.jpg";
import MLWPic2 from "../../assets/fighter/men-lightweight/pic2.jpg";
import MLWPic3 from "../../assets/fighter/men-lightweight/pic3.jpg";
import MLWPic4 from "../../assets/fighter/men-lightweight/pic4.jpg";
import MLWPic5 from "../../assets/fighter/men-lightweight/pic5.jpg";
import MLWPic6 from "../../assets/fighter/men-lightweight/pic6.jpg";
import MLWPic7 from "../../assets/fighter/men-lightweight/pic7.jpg";
import MLWPic8 from "../../assets/fighter/men-lightweight/pic8.jpg";
import MLWPic9 from "../../assets/fighter/men-lightweight/pic9.jpg";
import MLWPic10 from "../../assets/fighter/men-lightweight/pic10.jpg";
import MLWPic11 from "../../assets/fighter/men-lightweight/pic11.jpg";
import MLWPic12 from "../../assets/fighter/men-lightweight/pic12.jpg";
import MLWPic13 from "../../assets/fighter/men-lightweight/pic13.jpg";
import MLWPic14 from "../../assets/fighter/men-lightweight/pic14.jpg";
import MLWPic15 from "../../assets/fighter/men-lightweight/pic15.jpg";
import MLWPic16 from "../../assets/fighter/men-lightweight/pic16.jpg";
import MLWPic17 from "../../assets/fighter/men-lightweight/pic17.jpg";
import MLWPic18 from "../../assets/fighter/men-lightweight/pic18.jpg";
import MLWPic19 from "../../assets/fighter/men-lightweight/pic19.jpg";
import MLWPic20 from "../../assets/fighter/men-lightweight/pic20.jpg";


import MFWPic1 from "../../assets/fighter/men-featherweight/pic1.png";
import MFWPic2 from "../../assets/fighter/men-featherweight/pic2.png";
import MFWPic3 from "../../assets/fighter/men-featherweight/pic3.png";
import MFWPic4 from "../../assets/fighter/men-featherweight/pic4.png";
import MFWPic5 from "../../assets/fighter/men-featherweight/pic5.png";
import MFWPic6 from "../../assets/fighter/men-featherweight/pic6.png";
import MFWPic7 from "../../assets/fighter/men-featherweight/pic7.png";
import MFWPic8 from "../../assets/fighter/men-featherweight/pic8.png";
import MFWPic9 from "../../assets/fighter/men-featherweight/pic9.png";
import MFWPic10 from "../../assets/fighter/men-featherweight/pic10.png";


import MBWPic1 from "../../assets/fighter/men-bantamweight/pic1.png";
import MBWPic2 from "../../assets/fighter/men-bantamweight/pic2.png";
import MBWPic3 from "../../assets/fighter/men-bantamweight/pic3.png";
import MBWPic4 from "../../assets/fighter/men-bantamweight/pic4.png";
import MBWPic5 from "../../assets/fighter/men-bantamweight/pic5.png";
import MBWPic6 from "../../assets/fighter/men-bantamweight/pic6.png";
import MBWPic7 from "../../assets/fighter/men-bantamweight/pic7.png";


import WBWPic1 from "../../assets/fighter/women-bantamweight/pic1.jpg";
import WBWPic2 from "../../assets/fighter/women-bantamweight/pic2.jpg";
import WBWPic3 from "../../assets/fighter/women-bantamweight/pic3.jpg";
import WBWPic4 from "../../assets/fighter/women-bantamweight/pic4.jpg";

import WFWPic1 from "../../assets/fighter/women-flyweight/pic1.jpg";
import WFWPic2 from "../../assets/fighter/women-flyweight/pic2.jpg";


import WSWPic1 from "../../assets/fighter/women-strawweight/pic1.jpg";
import WSWPic2 from "../../assets/fighter/women-strawweight/pic2.jpg";
import WSWPic3 from "../../assets/fighter/women-strawweight/pic3.jpg";
import WSWPic4 from "../../assets/fighter/women-strawweight/pic4.jpg";


import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const Fighters = () => {
  const [showMen, setShowMen] = useState(true);
  const [showWomen, setShowWomen] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [tabValueWomen, setTabValueWomen] = useState("1");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeWomen = (event, newValue) => {
    setTabValueWomen(newValue);
  };

  return (
    <div className="fighters">
      <Helmet>
        <title>
          GFL MMA League | Team-Based MMA and Fighter Revenue Sharing
        </title>
        <meta
          name="description"
          content="The GFL MMA League is revolutionizing mixed martial arts with a team-based format, fair revenue sharing for fighters, and the biggest comeback stories of 2025."
        />
        <meta
          name="keywords"
          content="MMA league 2025,GFL MMA format,MMA fight teams 2025,fighter revenue sharing MMA,GFL MMA fighters 2025,top MMA fights 2025,team-based MMA,new MMA promotions 2025,GFL MMA comeback fighters"
        />
      </Helmet>
      <section className="title-scetion pt-0 pt-md-5">
        <div className="container">
          <h1 className="text-center pb-3">
            The Future of Combat Sports: Meet the Draft-Eligible Fighters!
          </h1>
          <div className="text-center text1">
          Explore the elite athletes poised to dominate the league. Divisions across men and women, ready to make history!
          </div>
          {/* <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="d-flex justify-content-around tags flex-wrap">
                    <a className="league-tags" href="#league-fighter" onClick={(e) => closeOffcanvas(e, "league-fighter")}>Fighter Benefits</a>
                    <a className="league-tags" href="#league-fan" onClick={(e) => closeOffcanvas(e, "league-fan")}>Fan Benefits</a>
                    <a className="league-tags" href="#league-partner" onClick={(e) => closeOffcanvas(e, "league-partner")}>Partner Benefits</a>
                    <a className="league-tags" href="#league-season" onClick={(e) => closeOffcanvas(e, "league-season")}>Season</a>
                    <a className="league-tags" href="#league-points" onClick={(e) => closeOffcanvas(e, "league-points")}>Points</a>
                  </div>
                </div>
              </div> */}
          {/* <div className="dashed-line my-5"></div> */}
          {/* <div className="text1">Discover the innovative model of the Global Fight League and the unique benefits it offers to fighters, fans, and partners</div> */}
        </div>
      </section>
      <div className="container pt-5">
        <div className="row pb-3">
          <div className="col-md">
            <h2 className="primary-color pb-2">Fighters</h2>
          </div>
          <div className="col-md text-md-end">
            <Button
              onClick={() => {
                setShowMen(true);
                setShowWomen(false);
              }}
              className={`button ${showMen ? "active" : ""}`}
            >
              Men's Divisions
            </Button>
            <Button
              onClick={() => {
                setShowMen(false);
                setShowWomen(true);
              }}
              className={`button ${showWomen ? "active" : ""}`}
            >
              Women's Divisions
            </Button>
          </div>
        </div>
        <div>
          {showMen && (
            // <Tabs
            //     defaultActiveKey="home"
            //     id="uncontrolled-tab-example"
            //     className="mb-3"
            // >
            //     <Tab eventKey="home" title="Home">
            //         Tab content for Home
            //     </Tab>
            //     <Tab eventKey="profile" title="Profile">
            //         Tab content for Profile
            //     </Tab>
            //     <Tab eventKey="contact" title="Contact">
            //         Tab content for Contact
            //     </Tab>
            // </Tabs>

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    // scrollButtons
                    allowScrollButtonsMobile
                  >
                    <Tab label="Heavyweight" value="1" />
                    <Tab label="Light Heavyweight" value="2" />
                    <Tab label="Middleweight" value="3" />
                    <Tab label="Welterweight" value="4" />
                    <Tab label="Lightweight" value="5" />
                    <Tab label="Featherweight" value="6" />
                    <Tab label="Bantamweight" value="7" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic11} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic12} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic13} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic14} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MLHWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLHWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLHWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLHWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLHWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic11} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="4">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic11} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="5">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic11} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic12} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic13} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic14} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic15} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic16} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic17} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic18} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic19} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic20} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="6">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="7">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          )}
        </div>
        <div>
          {showWomen && (
            // <Tabs
            //     defaultActiveKey="home-women"
            //     id="uncontrolled-tab-example"
            //     className="mb-3"
            // >
            //     <Tab eventKey="home-women" title="Home-women">
            //         Tab content for Home-women
            //     </Tab>
            //     <Tab eventKey="profile-women" title="Profile-women">
            //         Tab content for Profile-women
            //     </Tab>
            //     <Tab eventKey="contact-women" title="Contact-women">
            //         Tab content for Contact-women
            //     </Tab>
            // </Tabs>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tabValueWomen}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChangeWomen}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    // scrollButtons
                    allowScrollButtonsMobile
                  >
                    <Tab label="Bantamweight" value="1" />
                    <Tab label="Flyweight" value="2" />
                    <Tab label="Strawweight" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={WBWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WBWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WBWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WBWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={WFWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WFWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={WSWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WSWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WSWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WSWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          )}
        </div>
        {/* <div className="sub-text pb-4">The GFL season runs from April to November, with teams competing in a structured league format leading to a thrilling semifinals and finals to crown the GFL champion.</div>
              <div className="structure-box p-4">
                <div className="row gx-5">
                  <div className="col-md-4">
                      <h4>GFL MMA Regular Season: 
                      Global Competition</h4>
                      <div className="date-tag pb-2">April  - September</div>
                      <p>
                      Experience the excitement of the GFL MMA Regular Season, featuring 6 global teams competing across 10 weight divisions (7 men’s, 3 women’s) in 4 countries. Each team consists of 20 athletes (2 per weight division) and participates in 5 home/away events. The team with the highest point total at the end of the Regular Season will be crowned League Champions, while weight division-specific rankings will advance to the Semi-Finals Bracket.
                      </p>
                  </div>
                  <div className="col-md-4 border-lr">
                      <h4>GFL MMA Semi-Finals: Battle for Glory</h4>
                      <div className="date-tag pb-2">October 09 - 10</div>
                      <p>
                      In the thrilling Semi-Finals, Seed 1 faces Seed 4 and Seed 2 takes on Seed 3 in each division. These high-stakes matchups determine who advances to the GFL MMA Finals, showcasing the best talent in the league!
                      </p>
                  </div>
                  <div className="col-md-4">
                      <h4>GFL MMA Finals:
                      The Ultimate Showdown</h4>
                      <div className="date-tag pb-2">November 7</div>
                      <p>
                      Witness the top fighters from each team clash in the thrilling GFL MMA Finals, where champions are crowned and legacies are forged. Don’t miss the most exciting matchups of the season!
                      </p>
                  </div>
                </div>
              </div>
              <div className="pt-5 pb-0 pb-md-5">
                  <img src={Diagram} className="mw-100"></img>
                </div> */}
      </div>
      {/* <section className="py-5" id="league-points">
            <div className="container">
              <h2 className="primary-color">Points System</h2>
              <div className="pb-4">
              <p>
              Each fighter’s win contributes points to their team. Points are awarded based on victories, with additional bonuses for KO or submission finishes.
                  </p>
              </div>
              <div className="row g-md-5 gy-4 row-cols-3 row-cols-md-5 justify-content-center">
                <div className="col-md">
                  <div className="points-systme-box p-2 p-md-4 text-center">
                    <div className="count">+4</div>
                    <div className="title">Finish Wins</div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="points-systme-box p-2 p-md-4 text-center">
                    <div className="count">+3</div>
                    <div className="title">Decision Win</div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="points-systme-box p-2 p-md-4 text-center">
                    <div className="count">+2</div>
                    <div className="title">Draw</div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="points-systme-box p-2 p-md-4 text-center">
                    <div className="count">+1</div>
                    <div className="title">Decision Loss</div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="points-systme-box p-2 p-md-4 text-center">
                    <div className="count">0</div>
                    <div className="title">Finish Loss</div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
      {/* <section className="financial-benefits py-2 pb-md-5" id="league-fighter">
            <div className="container">
              <h2 className="primary-color  py-2 pb-md-5">Fighter Benefits</h2>
              <div className="row align-items-center gx-0 gx-md-5">
                <div className="col-md-6 pb-3 pb-md-0">
                  <img src={FighterBnefitsImg} className="mw-100"></img>
                </div>
                <div className="col-md-6">
                  <h5>50/50 Revenue Share</h5>
                  <p>Fighters earn a fair share of the revenue generated from events, ensuring their success is directly linked to the league’s success.</p>
                  <h5>Comprehensive Support</h5>
                  <p>Access to training resources, medical assistance, and promotional support to enhance their careers.</p>
                  <h5>Retirement Benefits</h5>
                  <p>A structured retirement fund to secure fighters' financial futures after their careers in the ring.</p>
                  <h5>Insurance Coverage</h5>
                  <p>Health and injury insurance fund to protect fighters from unexpected medical costs and support their well-being.</p>
                  <h5>Global Exposure</h5>
                  <p>Opportunity to compete on a world stage, increasing visibility and attracting sponsorships.</p>
                  <h5>Fighter-Centric Model</h5>
                  <p>A league designed to prioritize fighter well-being, safety, and career development.</p>
                </div>
              </div>
            </div>
          </section> */}
      {/* <section className="financial-benefits py-2 pb-md-5" id="league-fan">
            <div className="container">
              <h2 className="primary-color  py-2 pb-md-5">Fan Benefits</h2>
              <div className="row gx-0 gx-md-5">
                <div className="col-md-6 order-1 order-md-0">
                  <h5>Unmatched Access</h5>
                  <p>Live streaming of events, behind-the-scenes content, and exclusive interviews with fighters.</p>
                  <h5>Interactive Experience</h5>
                  <p>Engaging features like live polls, chats, and merchandise options during events.</p>
                  <h5>Community Engagement</h5>
                  <p>Join a passionate community of MMA enthusiasts and interact with fighters through social media and fan events.</p>
                  <h5>Loyalty Rewards</h5>
                  <p>Opportunities to earn rewards through participation, ticket purchases, and engagement with the GFL.</p>
                </div>
                <div className="col-md-6  order-0 order-md-1 pb-3 pb-md-0">
                  <img src={FanBnefitsImg} className="mw-100"></img>
                </div>
              </div>
            </div>
          </section> */}
      {/* <section className="financial-benefits py-2 pb-md-5" id="league-partner">
            <div className="container">
              <h2 className="primary-color py-2 pb-md-5">Partner Benefits</h2>
              <div className="row align-items-center gx-0 gx-md-5">
                <div className="col-md-6 pb-3 pb-md-0">
                  <img src={PartnerBnefitsImg} className="mw-100"></img>
                </div>
                <div className="col-md-6">
                  <h5>Brand Collaboration</h5>
                  <p>Opportunities for sponsorships, advertising, and co-promotions within a growing combat sports league.</p>
                  <h5>Diverse Monetization Channels</h5>
                  <p>Access to various revenue streams, including Pay-Per-View, merchandise sales, and NFT integrations.</p>
                  <h5>Innovative Technology</h5>
                  <p>Utilize cutting-edge tech and analytics to enhance fan engagement and optimize performance.</p>
                  <h5>Market Growth Potential</h5>
                  <p>Join a rapidly expanding league poised to attract millions of viewers and participants worldwide.</p>
                </div>
              </div>
            </div>
          </section> */}
      <div className="pt-3"></div>
      <ThemeContextProvider>
        <ContactForm />
      </ThemeContextProvider>
      <Footer />
    </div>
  );
};
export default Fighters;
