import * as React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Modal,
    Box,
    Typography,
    Button,
} from '@mui/material';
import { useThemeContext } from '../../themecontext';
import ATHLETES_DATA from './athletesJSON';

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'team', label: 'Team', minWidth: 100 },
    { id: 'weight', label: 'Weight Class', minWidth: 170, align: 'right' },
    { id: 'record', label: 'Record', minWidth: 170, align: 'right' },
    { id: 'reach', label: 'Reach And Height', minWidth: 170, align: 'right' },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function createData(name, team, weight, record, reach) {
    // const density = population / size;
    return { name, team, weight, record, reach };
}


export default function AthletesTable() {
    const { isDarkMode, toggleTheme } = useThemeContext();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [athletes, setAthletes] = React.useState({});
    const handleClose = () => setOpen(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const athleteDetails = (details) => {
        setAthletes(details);
        setOpen(true);
    };

    const filteredRows = ATHLETES_DATA.filter((row) =>
        row.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <TextField
                label="Search for your athlete"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 2 }}
            />
            <Paper sx={{ width: '100%' }}>
                {/* <TableContainer sx={{ maxHeight: 440 }}> */}
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, textAlign: 'center' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        onClick={() => athleteDetails(row)}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => setRowsPerPage(+e.target.value)}
                />
            </Paper>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-code"
            >
                <Box sx={{ ...style, bgcolor: isDarkMode ? '#2B3035' : '#fff', color: isDarkMode ? '#fff' : '#000' }}>
                    <h1 id="modal-modal-title" variant="h6" component="h2">
                        {athletes.name}
                    </h1>
                    <Typography id="modal-modal-code" sx={{ mt: 2 }}>
                        <b>Record:</b> {athletes.record}
                    </Typography>
                    <p><b>Weight Division</b> : {athletes.weight}</p>
                    <p><b>Team :</b> {athletes.team}</p>
                    <p><b>Background :</b> {athletes.background}</p>
                </Box>
            </Modal>
        </div>
    );
}
