import React, { useEffect } from "react";
import ContactForm from "../contact-form/contactForm";
import Button from "react-bootstrap/Button";
import Footer from "../footer/footer";
import "./gflArena.scss";
import { ThemeContextProvider } from "../../themecontext";

import GflArenaImg1 from "../../assets/gfl-arena-img1.png";
import GflArenaImg2 from "../../assets/gfl-arena-img2.png";
import GflArenaImg3 from "../../assets/gfl-arena-img3.png";
import GflArenaImg4 from "../../assets/gfl-arena-img4.png";

import GflArenaImg1M from "../../assets/gfl-arena-img1-m.png";
import GflArenaImg2M from "../../assets/gfl-arena-img2-m.png";
import GflArenaImg3M from "../../assets/gfl-arena-img3-m.png";
import GflArenaImg4M from "../../assets/gfl-arena-img4-m.png";

import GflArenaIcon1 from "../../assets/gfl-arena-icon1.svg";
import GflArenaIcon2 from "../../assets/gfl-arena-icon2.svg";
import GflArenaIcon3 from "../../assets/gfl-arena-icon3.svg";
import GflArenaIcon4 from "../../assets/gfl-arena-icon4.svg";
import { Helmet } from "react-helmet-async";
import { platformKombatPlus } from "../../utils/constants";


const GflArena = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="gfl-arena">
      <Helmet>
        <title>GFL APP | MMA Streaming with Real-Time Analytics and Interactive Features</title>
        <meta name="description" content="Watch MMA fights live on GFL APP with real-time analytics, interactive features, and low-latency streaming. The future of MMA viewing starts here."/>
        <meta name="keywords" content="MMA live streaming platform,GFL APP MMA fights,MMA fight stats,real-time MMA analytics,MMA streaming 2025,interactive MMA viewing,MMA fight platform,MMA event streaming,low-latency MMA streaming,MMA streaming service 2025"/>
      </Helmet>
      <section className="gfl-arena-hero d-flex justify-content-centers align-items-end">
        <div className="container text-center">
          <h1 className="mb-5">
          Your All-Access Home to Watch, Follow, and Experience Every <br></br>GFL Fight—Live, Anywhere, on Any Device
          </h1>
        </div>
      </section>
      <section className="py-3 py-md-5">
        <div className="container">
          <div className="row align-items-center pb-3 pb-md-5">
            <div className="col">
              <h2 className="primary-color m-0 text-nowrap">
                Watch Live Events in Real Time
              </h2>
            </div>
            <div className="col">
              <div className="border-top"></div>
            </div>
            <div className="col-auto text-end">
              <Button href="https://stage.kombatplus.com/" target="_blank">Watch Live</Button>
            </div>
          </div>
          <div className="section-box p-4">
            <h2 className="primary-color pb-3">
            Los Angeles Vs Miami
            </h2>
            <img src={GflArenaImg1} className="mw-100 web-img"></img>
            <img src={GflArenaImg1M} className="w-100 mobile-img"></img>
          </div>
        </div>
      </section>
      <section className="py-3 py-md-5">
        <div className="container">
          <h2 className="primary-color pb-2 pb-md-4">Live Fan Chat & Polls</h2>
          <div className="section-box p-4">
            <h2 className="primary-color pb-3">
              Los Angeles Vs Miami
            </h2>
            <img src={GflArenaImg2} className="mw-100 web-img"></img>
            <img src={GflArenaImg2M} className="w-100 mobile-img"></img>
          </div>
        </div>
      </section>
      <section className="py-3 py-md-5">
        <div className="container">
          <h2 className="primary-color pb-2 pb-md-4">
            Athlete Fan Meet & Greet
          </h2>
          <div className="section-box p-4">
          <img src={GflArenaImg3} className="mw-100 web-img mb-3"></img>
          <img src={GflArenaImg3M} className="w-100 mobile-img mb-3"></img>
            <div className="section-box2 py-3 px-4">
              <h3>Los Angeles Press Conference</h3>
              <p className="m-0">
              • Brought to you by GFL • Ask favorite athletes the most wanted questions • Get a deeper insight into the athlete mindset
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-3 py-md-5">
        <div className="container">
          <h2 className="primary-color  pb-2 pb-md-4">
          Real-Time Stats and Leaderboards
          </h2>
          <div className="section-box p-4">
          <img src={GflArenaImg4} className="mw-100 web-img mb-3"></img>
          <img src={GflArenaImg4M} className="w-100 mobile-img mb-3"></img>
          </div>
        </div>
      </section>
      <section className="py-3 py-md-5 get-ready">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center text-md-start">
                <h2>Get Ready for the Revolutionary Combat Sports Experience on GFL APP, powered by Kombat Plus!</h2>
                <p>Be among the first 100,000 fans to register on GFL and receive an exclusive airdrop of KOPL tokens when it launches later this year! These tokens are your key to unlocking the revolutionary combat sports experience—use them across the Kombat Plus platform to buy event tickets, access exclusive content, purchase fighter merchandise, and even participate in fan-driven polls and contests. KOPL tokens will give access to content from GFL APP and other combat sports promotions.</p>
            </div>
            
            <div className="col-md-6">
                <div className="why-box p-4 text-center">
                      <h3 className="primary-color fw-bold pb-4">Why you should Join</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <img src={GflArenaIcon1} className="why-icon"></img>
                          <h3>Exclusive Airdrop</h3>
                          <p>
                          Live streaming of events, behind-the-scenes content, and exclusive interviews with fighters.
                          </p>
                        </div>
                        <div className="col-md-6">
                          <img src={GflArenaIcon2} className="why-icon"></img>
                          <h3>VIP Access</h3>
                          <p>
                          Use KOPL to secure tickets to live events, purchase exclusive merchandise, and unlock premium content.
                          </p>
                        </div>
                        <div className="col-md-6">
                          <img src={GflArenaIcon3} className="why-icon"></img>
                          <h3>Fan Power</h3>
                          <p>
                          Participate in fan polls and vote on upcoming matchups, all powered by KOPL tokens.
                          </p>
                        </div>
                        <div className="col-md-6">
                          <img src={GflArenaIcon4} className="why-icon"></img>
                          <h3>Interactive Experience</h3>
                          <p>
                          Engage with your favorite fighters and promotions like never before with real-time interaction during events.
                          </p>
                        </div>
                      </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-3 py-md-5">
        <div className="container">
          <div className="signup-box p-3 p-md-5 text-center">
            <h2 className="primary-color pb-3">Sign up today and register to receive your KOPL tokens— <br></br>experience combat sports like never before on Kombat Plus!</h2>
            <Button variant="primary" href={`${platformKombatPlus}/signup`} target="_blank">Get Started</Button>
          </div>
        </div>
        </section>
      <ThemeContextProvider>
        <ContactForm />
      </ThemeContextProvider>
      <Footer />
    </div>
  );
};

export default GflArena;
