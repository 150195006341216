import React, { useEffect } from "react";
import AthletesTable from "../athletesTable/AthletesTable";
import Footer from "../footer/footer";
import Button from "react-bootstrap/Button";
import ContactForm from "../contact-form/contactForm";
import { ThemeContextProvider } from "../../themecontext";
import "./draft.scss";

import DraftImg1 from "../../assets/draft-img1.png";
import KeyDraftIcon1 from "../../assets/key-draft-icon1.svg";
import KeyDraftIcon2 from "../../assets/key-draft-icon2.svg";
import KeyDraftIcon3 from "../../assets/key-draft-icon3.svg";
import EventTimer from "../eventTimer/eventTimer";


const Draft = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="draft">
      <section className="draft-hero d-flex justify-content-centers">
        <div className="container">
          <h1 className="mb-3">
            Join the Global Fight League <br></br>MMA Draft 2025!
          </h1>
          <p>Be part of the action. Secure your spot in the league!</p>
          <div className="d-flex pb-4">
          <EventTimer/>
          </div>
          <Button variant="primary">Learn More</Button>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
          <div className="row align-items-end justify-content-center">
            <div className="col-md-5">
              <img src={DraftImg1} className="w-100"></img>
            </div>
            <div className="col-md-4">
              <h2 className="primary-color pb-4">What is the GFL MMA Draft?</h2>
              <p>
                The GFL MMA Draft is your chance to join a competitive team in
                the Global Fight League. This event will showcase aspiring
                athletes who will be selected to represent their teams across
                multiple weight divisions."
              </p>
              <ul>
                <li>Teams from various countries will select top athletes.</li>
                <li>
                  Athletes will be chosen based on performance and eligibility.
                </li>
                <li>Weight divisions include 7 men's and 2 women's classes.</li>
                <li>
                  Each team can have a minimum of 50% local talent to promote
                  regional representation.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="key-draft py-5">
        <div className="container">
        <h2 className="primary-color pb-4">Key Draft Information</h2>
        <div className="row justify-content-end">
          <div className="col-md-11">
              <h3>Eligibilty Criteria</h3>
              <p className="pb-4">To be eligible for the draft, athletes must meet the following criteria:
              </p>
              <div  className="row">
                <div className="col-md-4 text-center">
                  <img src={KeyDraftIcon1} className="key-icon pb-3"></img>
                  <div>Age: 18 years or older</div>
                </div>
                <div className="col-md-4 text-center">
                  <img src={KeyDraftIcon2} className="key-icon pb-3"></img>
                  <div>Minimum 5 amateur or professional fights</div>
                </div>
                <div className="col-md-4 text-center">
                  <img src={KeyDraftIcon3} className="key-icon pb-3"></img>
                  <div>Medical clearance required</div>
                </div>
              </div>
              <div className="dashed-line my-4">
              </div>
              <div className="d-flex justify-content-between align-items-center">
              <h3>Draft Date & Time</h3>
              <div className="date">January 15, 2025 - 6:00 PM EST</div>
              </div>
          </div>
        </div>
        </div>
      </section>
      
      <section className="eligible-athletes py-5">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
          <h2 className="primary-color pb-4">Eligible Athletes</h2>
          <div className="custom-tabs">
            <a className="active">Men’s Divisions</a>
            <a>Women’s Divisions</a>
          </div>
          </div>
          <ThemeContextProvider>
            <AthletesTable />
          </ThemeContextProvider>
        </div>
      </section>
      <section className="featured-teams pb-5">
        <div className="container">
          <h2 className="primary-color pb-3">Meet the Teams</h2>
          <div className="row g-0">
            <div className="col-md-3">
              <div className="team position-relative d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${require("../../assets/los-angeles-assassins.jpg")})` }}>
                <div className="text-center z-1">
                  <div className="title">Los Angeles Assassins</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="team position-relative d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${require("../../assets/new-york-force.jpg")})` }}>
                <div className="text-center z-1">
                  <div className="title">New York Force</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="team position-relative d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${require("../../assets/miami-diablos.jpg")})` }}>
                <div className="text-center z-1">
                  <div className="title">Miami Diablos</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="team position-relative d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${require("../../assets/montreal-rush.jpg")})` }}>
                <div className="text-center z-1">
                  <div className="title">Montreal Rush</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="team position-relative d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${require("../../assets/sao-paulo-oncas.jpg")})` }}>
                <div className="text-center z-1">
                  <div className="title">São Paulo Onças</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="team position-relative d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${require("../../assets/london-bullies.jpg")})` }}>
                <div className="text-center z-1">
                  <div className="title">London Bullies </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="team position-relative d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${require("../../assets/paris-maquis.jpg")})` }}>
                <div className="text-center z-1">
                  <div className="title">Paris Maquis</div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="team position-relative d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${require("../../assets/dubai-hunters.jpg")})` }}>
                <div className="text-center z-1">
                  <div className="title">Dubai Hunters</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="for-athletes my-5 text-center d-flex align-items-center">
        <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-7">
              <h2 className="primary-color pb-3">For Athletes</h2>
              <p>Are you ready to take your career to the next level? Submit your application to be considered for the GFL MMA Draft!</p>
              <Button variant="primary">Submit Your Application</Button>
              </div>
            </div>
        </div>
      
      </section>
      <ThemeContextProvider>
        <ContactForm />
      </ThemeContextProvider>
      <Footer />
    </div>
  );
};

export default Draft;
