import { Button } from '@mui/material';
import "./eventTimer.scss"
import React, { useEffect, useState } from 'react'

const EventTimer = () => {
  const [timeLeft, setTimeLeft] = useState({});

  const targetDate = new Date("2025-01-24T00:00:00");

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
          2,
          "0"
        ),
        hours: String(
          Math.floor((difference / (1000 * 60 * 60)) % 24)
        ).padStart(2, "0"),
        minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(
          2,
          "0"
        ),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  return (
    <div className='d-flex justify-content-center'>
            <div className="time-box text-center">
              <div className="count">{timeLeft.days}</div>
              <div className="title">Days</div>
            </div>
            <div className="colon">:</div>
            <div className="time-box text-center">
              <div className="count">{timeLeft.hours}</div>
              <div className="title">Hours</div>
            </div>
            <div className="colon">:</div>
            <div className="time-box text-center">
              <div className="count">{timeLeft.minutes}</div>
              <div className="title">Minutes</div>
            </div>
            <div className="colon">:</div>
            <div className="time-box text-center">
              <div className="count">{timeLeft.seconds}</div>
              <div className="title">Seconds</div>
            </div>
    </div>
  )
}

export default EventTimer