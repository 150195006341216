import React, { useCallback, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Button from "react-bootstrap/Button";
import "./draftApplication.scss";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { ThemeContextProvider } from "../../themecontext";
import Footer from "../footer/footer";

const initialState = {
  name: "",
  email: "",
  phone: "",
  nationality: "",
  record: "",
  bio: "",
};

const nationality = [
  {
    value: "-",
    label: "Nationality",
  },
  {
    value: "LosAngelesAssassins",
    label: "Los Angeles Assassins",
  },
  {
    value: "MiamiDiablos",
    label: "Miami Diablos",
  },
  {
    value: "NewYorkForce",
    label: "New York Force",
  },
  // {
  //   value: "MontrealRush",
  //   label: "Montreal Rush",
  // },
  {
    value: "SaoPauloOncas",
    label: "Sao Paulo Oncas",
  },
  {
    value: "LondonBullies",
    label: "London Bullies",
  },
  // {
  //   value: "ParisMaquis",
  //   label: "Paris Maquis",
  // },
  {
    value: "DubaiHunters",
    label: "Dubai Hunters",
  },
];

const DraftApplication = () => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleForm = useCallback((event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  }, []);

  const validateFormData = (data) => {
    let errors = {};
    if (data.name == "") {
      errors.name = "Required";
    }
    if (data.email == "") {
      errors.email = "Required";
    }
    if (!data.isChecked) {
      errors.isChecked = "Required";
    }
    if (
      data.favTeam == "" ||
      data.favTeam == "-" ||
      data.favTeam == "Select Favourite Team"
    ) {
      errors.favTeam = "Required";
    }
    setErrors(errors);
  };

  const saveForm = (e) => {
    e.preventDefault();
    const validateErrors = validateFormData(formData);
  };

  return (
    <ThemeContextProvider>
      <div className="draft-application-form pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h1>DRAFT APPLICATION</h1>
              <div className="row">
                <div className="col-md-10">
                <p>
              We are actively signing MMA athletes, making them eligible for the GFL MMA Draft on January 15, 2025. If you’re ready to be part of this revolutionary opportunity, submit your information and let us know of your interest.
              </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-box p-4">
                <form>
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        name="name"
                        placeholder="Name*"
                        value={formData.name}
                        onChange={handleForm}
                      />
                    </FormControl>

                    {errors && errors.name && (
                      <p className="error mb-0 text-start">{errors.name}</p>
                    )}
                  </div>
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        name="email"
                        placeholder="Email*"
                        value={formData.email}
                        onChange={handleForm}
                      />
                    </FormControl>
                    {errors && errors.email && (
                      <p className="error mb-0 text-start">{errors.email}</p>
                    )}
                  </div>
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        name="phone"
                        placeholder="Phone Number*"
                        value={formData.phone}
                        onChange={handleForm}
                      />
                    </FormControl>
                  </div>
                  <div className="pb-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      defaultValue="-"
                      placeholder="Nationality"
                      onChange={handleForm}
                      name="favTeam"
                      className="w-100"
                    >
                      {nationality.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          defaultValue="Okay"
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors && errors.favTeam && (
                      <p className="error mb-0 text-start">{errors.favTeam}</p>
                    )}
                  </div>
                  <div className="pb-3">
                    <FormControl className="w-100" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        name="record"
                        placeholder="Record"
                        value={formData.record}
                        onChange={handleForm}
                      />
                    </FormControl>
                  </div>
                  <div className="pb-3">
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      defaultValue="Athlete Bio"
                      className="w-100"
                      name="bio"
                      value={formData.record}
                      onChange={handleForm}
                    />
                  </div>
                  <div>
                    <div className="d-flex align-items-center pb-3">
                      <Checkbox
                        type="checkbox"
                        name="isChecked"
                        checked={formData.isChecked}
                        onChange={(e) => handleForm(e)}
                      />
                      <label for="checkbox">
                        {" "}
                        Agree with Terms and Privacy policy
                      </label>
                    </div>
                    {errors.isChecked && (
                      <p
                        className="error mb-0 text-start ps-2"
                        style={{ position: "relative", top: "-20px" }}
                      >
                        {errors.isChecked}
                      </p>
                    )}
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100 rounded-pill"
                    onClick={saveForm}
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </ThemeContextProvider>
  );
};

export default DraftApplication;
