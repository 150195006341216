import React, { useEffect } from "react";
import Footer from "../footer/footer";
import "./cookiePolicy.scss";
import { Helmet } from "react-helmet-async";

const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Helmet>
        <title>GFL MMA League | 2025 Fighter Comebacks & MMA's New Era</title>
        <meta property="og:title" content="GFL MMA League | 2025 Fighter Comebacks & MMA's New Era" />
        <meta name="description" content="Discover the GFL MMA League, launching in 2025 with top fighters returning to fight. Stay tuned for exclusive fighter updates, event announcements, and streaming details." />
        <meta name="keywords" content="MMA league 2025,GFL MMA league,MMA comeback 2025,mixed martial arts 2025,GFL fight schedule,MMA streaming platform,MMA promotions 2025,MMA fights 2025,top MMA fighters 2025" />
      </Helmet>
      <div className="privacy-policy">
        <div className="container">
          <h1 className="text-center pb-5">Cookie Policy</h1>
          <h2>Global Fight League (GFL) Cookie Policy</h2>
          <p>
            <strong>Effective Date:</strong> 1 November 2024
          </p>

          <h2>1. Purpose of Cookies</h2>
          <p>
            Cookies are essential for optimizing user experience, tracking
            engagement, and facilitating secure transactions. By using our site,
            you consent to our use of cookies in accordance with this Cookie
            Policy.
          </p>

          <h2>2. Types of Cookies</h2>

          <h3>a. Essential Cookies</h3>
          <p>
            Necessary for site operation, including login sessions and access to
            secure areas.
          </p>

          <h3>b. Performance Cookies</h3>
          <p>
            Track usage metrics, user activity, and engagement for optimization
            and performance analysis.
          </p>

          <h3>c. Advertising and Tracking Cookies</h3>
          <p>
            Collect data on interactions and preferences to provide targeted
            advertising and recommended content.
          </p>

          <h2>3. Managing Cookies</h2>
          <p>
            Users may adjust cookie preferences through browser settings or via
            on-site prompts where available. Disabling cookies may affect access
            to certain features.
          </p>

          <h2>4. Changes to Cookie Policy</h2>
          <p>
            We may update our Cookie Policy, and changes will be posted on this
            page. For questions, please contact us at{" "}
            <a href="mailto:privacy@gfl.com">privacy@gfl.com</a>.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
