import React, { useEffect } from "react";
import ContactForm from "../contact-form/contactForm";
import Button from "react-bootstrap/Button";
import Footer from "../footer/footer";
import "./news.scss";
import { ThemeContextProvider } from "../../themecontext";
import { Navigate, useNavigate } from "react-router-dom";

const News = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate()
  const readMoreFunc = () => {
    navigate("/news-details")
  }
  return (
    <div className="news">
      <section className="title-scetion py-5">
        <div className="container">
          <h1 className="text-center">Latest News</h1>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
            <div className="news-box p-4 mb-4">
                <div className="row">
                    <div className="col-md-8">
                        <h4 className="primary-color">Global Fight League Launches Four Teams for 2025 Inaugural Season</h4>
                        <p>The Global Fight League (GFL) is thrilled to announce that it has successfully raised funds to launch four teams for its inaugural 2025 season, marking a significant milestone in establishing the world’s first professional MMA league with a 50% revenue share for athletes…</p>
                        <a className="readmore" onClick={readMoreFunc}>Read More</a>
                    </div>
                    <div className="col-md-4 dat-text text-md-end">
                    <span className="date-dot me-1"></span>July 17, 2024
                    </div>
                </div>
            </div>
            <div className="news-box p-4 mb-4">
                <div className="row">
                    <div className="col-md-8">
                        <h4 className="primary-color">Harrison Rogers Commits $10 Million Dollars to Secure a Phoenix Franchise in the Global Fight League. GFL Announces Groundbreaking Docuseries to Launch the World's First Team-Based Global MMA League</h4>
                        <p>At Ultimate Warriors MMA, we are passionate about martial arts and helping individuals achieve their goals. Located in the United States, we offer a wide range of classes for all skill levels…</p>
                        <a className="readmore">Read More</a>
                    </div>
                    <div className="col-md-4 dat-text text-md-end">
                    <span className="date-dot me-1"></span>March 2024
                    </div>
                </div>
            </div>
        </div>
      </section>
      <ThemeContextProvider>
        <ContactForm />
      </ThemeContextProvider>
      <Footer />
    </div>
  );
};

export default News;
