import React, { useEffect } from "react";
import Footer from "../footer/footer";
import "./termsOfService.scss";
import { Helmet } from "react-helmet-async";

const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>GFL MMA League | 2025 Fighter Comebacks & MMA's New Era</title>
        <meta
          property="og:title"
          content="GFL MMA League | 2025 Fighter Comebacks & MMA's New Era"
        />
        <meta
          name="description"
          content="Discover the GFL MMA League, launching in 2025 with top fighters returning to fight. Stay tuned for exclusive fighter updates, event announcements, and streaming details."
        />
        <meta
          name="keywords"
          content="MMA league 2025,GFL MMA league,MMA comeback 2025,mixed martial arts 2025,GFL fight schedule,MMA streaming platform,MMA promotions 2025,MMA fights 2025,top MMA fighters 2025"
        />
      </Helmet>
      <div className="privacy-policy">
        <div className="container">
          <h1 className="text-center pb-5">Terms of Service</h1>
          <h2>Global Fight League (GFL) Terms of Service</h2>
          <p>
            <strong>Effective Date:</strong> 1 November 2024
          </p>

          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing or using GFL and GFL APP, you agree to comply with
            these Terms of Service. Unauthorized use of the platform or
            violation of these Terms may result in account suspension or
            termination.
          </p>

          <h2>2. Eligibility and Account Creation</h2>
          <p>
            Users must be of legal age in their jurisdiction to create an
            account and participate in token transactions. By registering, you
            agree to provide accurate information and are responsible for all
            activities conducted through your account.
          </p>

          <h2>3. Use of Platform and Content</h2>
          <p>
            All content on the GFL website and GFL APP, including video streams,
            live interactions, and NFTs, is proprietary to GFL or licensed
            partners. Unauthorized use, such as reselling or unauthorized
            reproduction, is prohibited.
          </p>
          <p>
            <strong>User Content:</strong> When submitting content (e.g.,
            comments or photos), you grant GFL a license to use, display, and
            distribute it within the platform for promotion and engagement
            purposes.
          </p>

          <h2>4. Payment and Refund Policy</h2>
          <p>
            All purchases made on GFL APP (tickets, subscriptions, NFTs) are
            final, with limited exceptions in cases of technical errors or
            service unavailability. Payment details are processed in accordance
            with our Privacy Policy.
          </p>

          <h2>5. Blockchain and Web3 Transactions</h2>
          <p>
            Users participating in blockchain transactions (e.g., purchasing
            NFTs, GFL Tokens) understand and agree that blockchain transactions
            are irreversible and secure via decentralized protocols. GFL is not
            liable for lost or inaccessible tokens due to wallet issues.
          </p>

          <h2>6. Prohibited Activities</h2>
          <p>Prohibited activities include:</p>
          <ul>
            <li>
              <strong>Disruptive Behavior:</strong> Misuse of chat, offensive
              language, or harassment.
            </li>
            <li>
              <strong>Unauthorized Automation:</strong> Data mining, scraping,
              or use of bots to engage with the platform.
            </li>
            <li>
              <strong>Fraud and Misrepresentation:</strong> Any fraudulent
              activity, including use of another person’s identity.
            </li>
          </ul>

          <h2>7. Limitation of Liability</h2>
          <p>
            GFL is not liable for indirect or incidental damages resulting from
            the use of our services. This includes loss of access to tokens,
            content, or features due to technical issues.
          </p>

          <h2>8. Governing Law and Dispute Resolution</h2>
          <p>
            These Terms are governed by the laws of Nevada. Any disputes will be
            resolved through arbitration in Clark County, Nevada.
          </p>

          <h2>9. Amendments to Terms</h2>
          <p>
            We may amend these Terms periodically. Continued use of GFL APP
            constitutes acceptance of the new Terms.
          </p>
          <h2>10. Communication Consent</h2>
          <p>
            By creating an account, you agree to receive emails and text
            messages from GFL regarding account updates, transactions, and
            promotional offers. Marketing communications require your explicit
            consent, which can be withdrawn at any time by following the
            unsubscribe instructions in the message. Standard carrier rates may
            apply for SMS.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;
