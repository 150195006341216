import React, { useEffect, useState } from "react";
import ContactForm from "../contact-form/contactForm";
import Button from "react-bootstrap/Button";
import Footer from "../footer/footer";
import "./league.scss";
import { ThemeContextProvider } from "../../themecontext";
import Diagram from "../../assets/diagram.png";
import fighterBnefits from "../../assets/fighterBenefits.jpg";
import FanBnefitsImg from "../../assets/fan-benefits-img.png";
import PartnerBnefitsImg from "../../assets/partner-benefits-img.png";
import { Helmet } from "react-helmet-async";
import seasonStructure from "../../assets/seasonStructure.jpg";
import pointsSystem from "../../assets/pointsSystem.jpg";

const League = () => {
  const [scrollToSection, setScrollToSection] = useState(null);
  const navbarHeight = 60;
  
  const closeOffcanvas = (event, sectionId) => {
    event.preventDefault();
    setScrollToSection(sectionId);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    if (scrollToSection) {
      const section = document.getElementById(scrollToSection);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: sectionTop - navbarHeight,
          behavior: "smooth"
        });
      }
      setScrollToSection(null);
    }
  }, [scrollToSection]);

  return (
    <div className="league">
      <Helmet>
        <title>GFL MMA League | Team-Based MMA and Fighter Revenue Sharing</title>
        <meta name="description" content="The GFL MMA League is revolutionizing mixed martial arts with a team-based format, fair revenue sharing for fighters, and the biggest comeback stories of 2025." />
        <meta name="keywords" content="MMA league 2025,GFL MMA format,MMA fight teams 2025,fighter revenue sharing MMA,GFL MMA fighters 2025,top MMA fights 2025,team-based MMA,new MMA promotions 2025,GFL MMA comeback fighters" />
      </Helmet>
      <section className="title-scetion pt-0 pt-md-5">
        <div className="container">
          <h1 className="text-center pb-3">How the GFL Works</h1>
          <div  className="text-center text1">Discover the innovative model of the Global Fight League and the unique benefits it offers to fighters.</div>
          {/* <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="d-flex justify-content-around tags flex-wrap">
                <a className="league-tags" href="#league-fighter" onClick={(e) => closeOffcanvas(e, "league-fighter")}>Fighter Benefits</a>
                <a className="league-tags" href="#league-fan" onClick={(e) => closeOffcanvas(e, "league-fan")}>Fan Benefits</a>
                <a className="league-tags" href="#league-partner" onClick={(e) => closeOffcanvas(e, "league-partner")}>Partner Benefits</a>
                <a className="league-tags" href="#league-season" onClick={(e) => closeOffcanvas(e, "league-season")}>Season</a>
                <a className="league-tags" href="#league-points" onClick={(e) => closeOffcanvas(e, "league-points")}>Points</a>
              </div>
            </div>
          </div> */}
          {/* <div className="dashed-line my-5"></div> */}
          {/* <div className="text1">Discover the innovative model of the Global Fight League and the unique benefits it offers to fighters, fans, and partners</div> */}
        </div>
      </section>
      <section className="season-structure pt-5">
        <div className="container">
          <h2  className="primary-color pb-2">Season Structure</h2>
          {/* <div className="sub-text pb-4">The GFL season runs from April to November, with teams competing in a structured league format leading to a thrilling semifinals and finals to crown the GFL champion.</div> */}
          {/* <div className="structure-box p-4">
            <div className="row gx-5">
              <div className="col-md-4">
                  <h4>GFL MMA Regular Season: 
                  Global Competition</h4>
                  <div className="date-tag pb-2">April  - September</div>
                  <p>
                  Experience the excitement of the GFL MMA Regular Season, featuring 6 global teams competing across 10 weight divisions (7 men’s, 3 women’s) in 4 countries. Each team consists of 20 athletes (2 per weight division) and participates in 5 home/away events. The team with the highest point total at the end of the Regular Season will be crowned League Champions, while weight division-specific rankings will advance to the Semi-Finals Bracket.
                  </p>
              </div>
              <div className="col-md-4 border-lr">
                  <h4>GFL MMA Semi-Finals: Battle for Glory</h4>
                  <div className="date-tag pb-2">October 09 - 10</div>
                  <p>
                  In the thrilling Semi-Finals, Seed 1 faces Seed 4 and Seed 2 takes on Seed 3 in each division. These high-stakes matchups determine who advances to the GFL MMA Finals, showcasing the best talent in the league!
                  </p>
              </div>
              <div className="col-md-4">
                  <h4>GFL MMA Finals:
                  The Ultimate Showdown</h4>
                  <div className="date-tag pb-2">November 7</div>
                  <p>
                  Witness the top fighters from each team clash in the thrilling GFL MMA Finals, where champions are crowned and legacies are forged. Don’t miss the most exciting matchups of the season!
                  </p>
              </div>
            </div>
          </div> */}
          <div className="pt-5 pb-0 pb-md-5">
              <img src={seasonStructure} className="mw-100"></img>
            </div>
        </div>
      </section>
      <section className="py-5" id="league-points">
        <div className="container">
          <h2 className="primary-color">Points System</h2>
          <div className="pt-5 pb-0 pb-md-5">
              <img src={pointsSystem} className="mw-100"></img>
            </div>
          {/* <div className="pb-4">
          <p>
          Each fighter’s win contributes points to their team. Points are awarded based on victories, with additional bonuses for KO or submission finishes.
              </p>
          </div>
          <div className="row g-md-5 gy-4 row-cols-3 row-cols-md-5 justify-content-center">
            <div className="col-md">
              <div className="points-systme-box p-2 p-md-4 text-center">
                <div className="count">+4</div>
                <div className="title">Finish Wins</div>
              </div>
            </div>
            <div className="col-md">
              <div className="points-systme-box p-2 p-md-4 text-center">
                <div className="count">+3</div>
                <div className="title">Decision Win</div>
              </div>
            </div>
            <div className="col-md">
              <div className="points-systme-box p-2 p-md-4 text-center">
                <div className="count">+2</div>
                <div className="title">Draw</div>
              </div>
            </div>
            <div className="col-md">
              <div className="points-systme-box p-2 p-md-4 text-center">
                <div className="count">+1</div>
                <div className="title">Decision Loss</div>
              </div>
            </div>
            <div className="col-md">
              <div className="points-systme-box p-2 p-md-4 text-center">
                <div className="count">0</div>
                <div className="title">Finish Loss</div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section className="financial-benefits py-2 pb-md-5" id="league-fighter">
        <div className="container">
          <h2 className="primary-color  py-2 pb-md-5">Fighter Benefits</h2>
          <div className="row align-items-center gx-0 gx-md-5">
            <div className="col-md-6 pb-3 pb-md-0">
              <img src={fighterBnefits} className="mw-100 rounded-4"></img>
            </div>
            <div className="col-md-6">
              <h5>50/50 Revenue Share</h5>
              <p>Fighters earn a fair share of the revenue generated from events, ensuring their success is directly linked to the league’s success.</p>
              <h5>Comprehensive Support</h5>
              <p>Access to training resources, medical assistance, and promotional support to enhance their careers.</p>
              <h5>Retirement Benefits</h5>
              <p>A structured retirement fund to secure fighters' financial futures after their careers in the ring.</p>
              <h5>Insurance Coverage</h5>
              <p>Health and injury insurance fund to protect fighters from unexpected medical costs and support their well-being.</p>
              <h5>Global Exposure</h5>
              <p>Opportunity to compete on a world stage, increasing visibility and attracting sponsorships.</p>
              <h5>Fighter-Centric Model</h5>
              <p>A league designed to prioritize fighter well-being, safety, and career development.</p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="financial-benefits py-2 pb-md-5" id="league-fan">
        <div className="container">
          <h2 className="primary-color  py-2 pb-md-5">Fan Benefits</h2>
          <div className="row gx-0 gx-md-5">
            <div className="col-md-6 order-1 order-md-0">
              <h5>Unmatched Access</h5>
              <p>Live streaming of events, behind-the-scenes content, and exclusive interviews with fighters.</p>
              <h5>Interactive Experience</h5>
              <p>Engaging features like live polls, chats, and merchandise options during events.</p>
              <h5>Community Engagement</h5>
              <p>Join a passionate community of MMA enthusiasts and interact with fighters through social media and fan events.</p>
              <h5>Loyalty Rewards</h5>
              <p>Opportunities to earn rewards through participation, ticket purchases, and engagement with the GFL.</p>
            </div>
            <div className="col-md-6  order-0 order-md-1 pb-3 pb-md-0">
              <img src={FanBnefitsImg} className="mw-100"></img>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="financial-benefits py-2 pb-md-5" id="league-partner">
        <div className="container">
          <h2 className="primary-color py-2 pb-md-5">Partner Benefits</h2>
          <div className="row align-items-center gx-0 gx-md-5">
            <div className="col-md-6 pb-3 pb-md-0">
              <img src={PartnerBnefitsImg} className="mw-100"></img>
            </div>
            <div className="col-md-6">
              <h5>Brand Collaboration</h5>
              <p>Opportunities for sponsorships, advertising, and co-promotions within a growing combat sports league.</p>
              <h5>Diverse Monetization Channels</h5>
              <p>Access to various revenue streams, including Pay-Per-View, merchandise sales, and NFT integrations.</p>
              <h5>Innovative Technology</h5>
              <p>Utilize cutting-edge tech and analytics to enhance fan engagement and optimize performance.</p>
              <h5>Market Growth Potential</h5>
              <p>Join a rapidly expanding league poised to attract millions of viewers and participants worldwide.</p>
            </div>
          </div>
        </div>
      </section> */}
      
      
      <ThemeContextProvider>
        <ContactForm />
      </ThemeContextProvider>
      <Footer />
    </div>
  );
};

export default League;
